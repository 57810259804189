import { DeviceTypeConst } from "../constants/DeviceTypeConst";

const deviceHelper = {

	fromFiscalNumberToModel: function (fiscalNumber) {
		let modello = "";
		if(fiscalNumber) {
			switch(fiscalNumber.substring(0,5).toUpperCase()) {
				case "94ISM":
					modello = DeviceTypeConst[2];
					break;
				case "94IBQ":
					modello = DeviceTypeConst[1];
					break;
				default: 
					modello = "";
					break;
			}
		}
		return modello;
	},

	fromFiscalNumberToDeviceType: function (fiscalNumber) {
		const model = this.fromFiscalNumberToModel(fiscalNumber);
		let deviceType = "";
		switch(model.toLowerCase()) {
			case "basiq":
				deviceType = 1;
				break;
			case "smart":
				deviceType = 2;
				break;
			default:
				deviceType = "";
				break;
		}
		return deviceType;
	}

}

export default deviceHelper;