import { faCaretSquareDown, faCaretSquareUp, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Input, Row, Table } from 'reactstrap';
import { DeviceTypeConst } from "../../constants/DeviceTypeConst.js";
import * as ConfigurationAPI from '../../services/configuration.js';
import TablePagination from '../Common/Pagination/TablePagination.jsx';
import './configuration.css';
import ListRow from './ListRow.jsx';
import DeleteConfigurationModal from './Modal/DeleteConfigurationModal';
import EditConfigurationModal from './Modal/EditConfigurationModal';
import ResponseModal from './Modal/ResponseModal';

const List = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [configurationList, setConfigurationList] = useState([]);
    const [filters, setFilters] = useState(
        {
            configurationIdAutoConfig: null,
            order_params:{
                name: "timestamp",
                sort: "desc"
            },
            search_params: {
                code: "",
                activity_name: "",
                business_name: "",
                vat_code: "",
                address: "",
                zip_code: "",
				device_type: ""
            },
			hideLinked: props.hideAssociatedConfig,
            pageSize: 10,
            currentPage: 1
        }
    )
    const [deleteModal, setDeleteModal] = useState({isDeleteModalOpen: false,isConfigDeleteInProgress: false,isDeleteSuccessful: null});
    const [editModal, setEditModal] = useState({isEditModalOpen: false,isConfigEditInProgress: false,isEditSuccessful: null});
    const [alertDanger, setAlertDanger] = useState({isAlertDangerVisible: false,isAlertSuccessVisible: false});
    const [currentConfiguration, setCurrentConfiguration] = useState({
        currentConfigurationId: 0,
        currentConfigurationCode: '',
        savedConfigurationId: 0,
        newInsertedConfiguration:{            
            activity_name: '',
            business_name: '',
            vat_code: '',
            fiscal_code: ''
        }
    });
    const [isDuplicationAction, setIsDuplicationAction] = useState(null);
    const [responseModal, setResponseModal] = useState({isResponseModalOpen: false, isResponseModalOpened: false});
    const [totals, setTotals] = useState({totalPages: 0, totalRecordCount: 0});
    const [currentDeviceId, setCurrentDeviceId] = useState(undefined);

	const deviceTypeOpts = [
		{index: "", value: DeviceTypeConst[0]},
		{index: 1, value: DeviceTypeConst[1]},
		{index: 2, value: DeviceTypeConst[2]}
	]



    useEffect(() => {
        getConfigurationList();
    },[filters, props.hideAssociatedConfig]);


    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            configurationIdAutoConfig: props.configurationIdAutoConfig
        }))
    },[props.configurationIdAutoConfig]);

    useEffect(() => {
        setCurrentDeviceId(props.deviceIdAutoConfig);
    },[props.deviceIdAutoConfig]);


    useEffect(() => {
        if(deleteModal.isDeleteSuccessful === true){
            getConfigurationList();
        }
        if(deleteModal.isDeleteSuccessful !== null){
            setAlertDanger((prevState) => ({
                ...prevState,
                isAlertDangerVisible: !deleteModal.isDeleteSuccessful,
                isAlertSuccessVisible: deleteModal.isDeleteSuccessful
            }))
        }

    },[deleteModal.isDeleteSuccessful]);

    /**
     * 
     */
    const handleChangeViewedItems = (event) => {
        setFilters((pervFilters) => ({
            ...pervFilters,
            pageSize: event.target.value
        }))
    }

    /**
     * listens for input text changes over configurations table search bar
     */
    const onChangeSearchInput = (event, inputName) => {
        setFilters((pervFilters) => ({
            ...pervFilters,
            search_params: {
                ...pervFilters.search_params,
                [inputName]: event.target.value.trim()
            }
        }))
    }

    /**
     * changes column and/or sorting
     */
    const orderByColumn = (columnName) => {
        let currentSorting = '';

        if(columnName === filters.order_params.name){
            currentSorting = filters.order_params.sort === "desc" ? "asc" : "desc";
        }
        else {
            if(columnName === "id") currentSorting = "desc";
            else currentSorting = "asc";
        }

        setFilters((pervFilters) => ({
            ...pervFilters,
            order_params: {
                ...pervFilters.order_params,
                name: columnName,
                sort:currentSorting
            }
        }))

    }

    /**
     * 
     */
    const changePage = (value) => {
        setFilters((pervFilters) => ({
            ...pervFilters,
            currentPage: value
        }))
    }

    const toggleDeleteModal = (currentConfigurationId) => {
        setDeleteModal((prevState) => ({
            ...prevState,
            isDeleteModalOpen: !prevState.isDeleteModalOpen,
            isDeleteSuccessful: null,
            currentConfigurationId: currentConfigurationId
        }))
    }
 
    const toggleEditModal = (currentConfigurationId, currentConfigurationCode, isDuplicationAction) => {
        if(editModal.isEditModalOpen){
            setCurrentDeviceId(undefined);
        }
        setEditModal((prevState) => ({
            ...prevState,
            isEditModalOpen: !prevState.isEditModalOpen
        }));
        setIsDuplicationAction(isDuplicationAction);
        setCurrentConfiguration((prevState) => ({
            ...prevState,
            currentConfigurationId: currentConfigurationId,
            currentConfigurationCode: currentConfigurationCode
        }))
    }

    const toggleResponseModal = () => {
        setCurrentDeviceId(undefined);
        setResponseModal((prevState) => ({
            ...prevState,
            isResponseModalOpen: !prevState.isResponseModalOpen,
            isResponseModalOpened: !prevState.isResponseModalOpened
        }))

    }

    const onAlertDangerDismiss = () => {
        setAlertDanger((prevState) => ({
            ...prevState,
            isAlertDangerVisible: false
        }))
    }

    const onAlertSuccessDismiss = () => {
        setAlertDanger((prevState) => ({
            ...prevState,
            isAlertSuccessVisible: false
        }))
    }

    /**
     * When a configuration is already linked with one or many devices, edit is not directly possible
     * Hovewer, when the user edits an already linked configuration, this one is duplicated and inserted in database
     * with a new configuration id
     * 
     * This function closes edit modal and then opens a Response Modal where all activity data is showed besides the 
     * configuration id
     */
    const closeEditModalInInsertOperation = (isSuccessfulOperation, configurationId, customerModuleWithoutReceiptData) => {
        toggleEditModal(configurationId);
        
        let newInsertedConfiguration = {
            activity_name: customerModuleWithoutReceiptData.activity_name,
            business_name: customerModuleWithoutReceiptData.business_name,
            vat_code: customerModuleWithoutReceiptData.vat_code,
            fiscal_code: customerModuleWithoutReceiptData.fiscal_code
        }

        if(isSuccessfulOperation){
            setResponseModal((prevState) => ({
                ...prevState,
                isResponseModalOpened: true,
            }))
            setCurrentConfiguration((prevState) => ({
                ...prevState,
                savedConfigurationId: configurationId,
                newInsertedConfiguration: newInsertedConfiguration
            }))
        }
    }

    //
    //SERVICES
    //

    /**
     * deletes a configuration from system
     */
    const deleteConfiguration = () => {
        setDeleteModal((prevState) => ({
            ...prevState,
            isConfigDeleteInProgress: true
        }))

        ConfigurationAPI.deleteById(deleteModal.currentConfigurationId)
        .then(response => {
            setDeleteModal((prevState) => ({
                ...prevState,
                isConfigDeleteInProgress: false,
                isDeleteModalOpen: false,
                isDeleteSuccessful: response.status === 200 ? true : false
            }))
        })
        .catch(err => {
            console.log(err);
            setDeleteModal((prevState) => ({
                ...prevState,
                isConfigDeleteInProgress: false,
                isDeleteModalOpen: false
            }))
        });
    }

    const getConfigurationList = () => {
        setIsLoading(true);

        ConfigurationAPI.getAll(filters.currentPage, filters.pageSize, filters.search_params, filters.order_params, props.hideAssociatedConfig)
            .then(response => {
                if(response.status === 200){
                    const configList = response?.data?.data ?? [];
                    setConfigurationList(configList);
                    setTotals((prevState) => ({
                        ...prevState,
                        totalPages: response.data.total_pages,
                        totalRecordCount: response.data.total_record_count
                    }))
                    setIsLoading(false);
                    // only if I have preselected the configuration from auto configuration 
                    if(filters.configurationIdAutoConfig !== null && configList.length > 0){
                        const configurationId = filters.configurationIdAutoConfig;
                        const configurationFound = configList.find(config => +config.id === +configurationId);
                        // copy the configuration preselected
                        if(configurationFound)
                            toggleEditModal(configurationFound.id, configurationFound.code, true);
                    }
                }
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const toggleFavourite = async (id, is_favorite) => {
        setIsLoading(true);
        setFilters((prevFilters) => ({
            ...prevFilters,
            currentPage: 1
        }))

        const isFavouriteResponse = await ConfigurationAPI.setIsFavourite(id, is_favorite);
        if(isFavouriteResponse.status === 200){
            getConfigurationList();
        }
    }
    const renderOrderIcon = (column_name) => {
        if(filters.order_params.name === column_name){
            return (
                <span className="float-right">
                    <FontAwesomeIcon icon={filters.order_params.sort === "desc" ? faCaretSquareDown : faCaretSquareUp} />
                </span>
            )
        }else{
            return (<span className="float-right"></span>)
        }
    }

    return (
		<React.Fragment>
			<div className="clearfix">&nbsp;</div>
			<React.Fragment>
				<div className="clearfix">&nbsp;</div>
				<Alert color="danger" isOpen={alertDanger.isAlertDangerVisible} toggle={onAlertDangerDismiss}>
					Cancellazione non riuscita
				</Alert>
				<Alert color="success" isOpen={alertDanger.isAlertSuccessVisible} toggle={onAlertSuccessDismiss}>
					Cancellazione effettuata con successo
				</Alert>
			</React.Fragment>
			<Row>
				<Col>
					Visualizza{" "}
					<select onChange={handleChangeViewedItems}>
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>{" "}
					Elementi
					<div className="clearfix">&nbsp;</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table className="mt-5" striped responsive hover size="sm">
						<thead>
							<tr>
								<th style={{ cursor: "pointer" }} onClick={(e) => orderByColumn("timestamp")}>
									Creazione {renderOrderIcon("timestamp")}
								</th>
								<th width="5%" style={{ cursor: "pointer" }} onClick={(e) => orderByColumn("id")}>
									Codice {renderOrderIcon("id")}
								</th>
								<th></th>
								<th>Tipo Dispositivo</th>
								<th style={{ cursor: "pointer" }} onClick={(e) => orderByColumn("activity_name")}>
									Nome Esercizio {renderOrderIcon("activity_name")}
								</th>
								<th style={{ cursor: "pointer" }} onClick={(e) => orderByColumn("business_name")}>
									Ragione Sociale {renderOrderIcon("business_name")}
								</th>
								<th width="1%" style={{ cursor: "pointer" }} onClick={(e) => orderByColumn("vat_code")}>
									Partita IVA {renderOrderIcon("vat_code")}
								</th>
								<th style={{ cursor: "pointer" }} onClick={(e) => orderByColumn("address")}>
									Indirizzo {renderOrderIcon("address")}
								</th>
								<th width="1%" style={{ cursor: "pointer" }} onClick={(e) => orderByColumn("zip_code")}>
									Cap {renderOrderIcon("zip_code")}
								</th>
								<th>Azioni</th>
							</tr>
							<tr>
								<th></th>

								<th>
									<Input type="text" value={filters.search_params.code} onChange={(e) => onChangeSearchInput(e, "code")} />
								</th>
								<th></th>
								<th>
									<Input
										value={filters.search_params.device_type}
										type="select"
										name="deviceType"
										id="idDeviceType"
										onChange={(e) => onChangeSearchInput(e, "device_type")}
									>
										{deviceTypeOpts.map((opt) => (
											<option key={opt.index} value={opt.index}>
												{opt.value}
											</option>
										))}
									</Input>
								</th>
								<th>
									<Input type="text" value={filters.search_params.activity_name} onChange={(e) => onChangeSearchInput(e, "activity_name")} />
								</th>
								<th>
									<Input type="text" value={filters.search_params.business_name} onChange={(e) => onChangeSearchInput(e, "business_name")} />
								</th>
								<th>
									<Input type="text" value={filters.search_params.vat_code} onChange={(e) => onChangeSearchInput(e, "vat_code")} />
								</th>
								<th>
									<Input type="text" value={filters.search_params.address} onChange={(e) => onChangeSearchInput(e, "address")} />
								</th>
								<th>
									<Input type="text" value={filters.search_params.zip_code} onChange={(e) => onChangeSearchInput(e, "zip_code")} />
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{!isLoading
								? configurationList.map((configuration, index) => (
										<ListRow
											key={"configuration_row" + index}
											configuration={configuration}
											toggleEditModal={toggleEditModal}
											toggleDeleteModal={toggleDeleteModal}
											toggleFavourite={toggleFavourite}
										/>
								  ))
								: null}
						</tbody>
					</Table>
					{isLoading ? (
						<React.Fragment>
							<Row>
								<Col>
									<div style={{ textAlign: "center" }}>
										<p>Caricamento in corso...</p>
										<p>
											<FontAwesomeIcon icon={faCircleNotch} spin size="3x" />
										</p>
									</div>
								</Col>
							</Row>
						</React.Fragment>
					) : (
						""
					)}
					<div className="clearfix">&nbsp;</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<TablePagination
						disabled={isLoading || configurationList.length === 0 ? true : false}
						currentPage={filters.currentPage}
						totalPages={totals.totalPages}
						pageSize={filters.pageSize}
						recordCount={totals.totalRecordCount}
						changePage={changePage}
					/>
				</Col>
			</Row>
			<DeleteConfigurationModal
				isDeleteModalOpen={deleteModal.isDeleteModalOpen}
				toggleDeleteModal={toggleDeleteModal}
				isConfigDeleteInProgress={deleteModal.isConfigDeleteInProgress}
				deleteConfiguration={deleteConfiguration}
				isDeleteSuccessful={deleteModal.isDeleteSuccessful}
			/>
			{editModal.isEditModalOpen ? (
				<EditConfigurationModal
					idDevice={currentDeviceId ?? undefined} // from autoConfig, giving matricola from qrCode
					toggleEditModal={toggleEditModal}
					currentConfigurationId={currentConfiguration.currentConfigurationId}
					closeEditModalInInsertOperation={closeEditModalInInsertOperation}
					currentConfigurationCode={currentConfiguration.currentConfigurationCode}
					isDuplicationAction={isDuplicationAction}
					reloadConfigurationList={getConfigurationList}
					hasPlan={props.hasPlan ?? null}
				/>
			) : (
				""
			)}
			{responseModal.isResponseModalOpened ? (
				<ResponseModal
					goBackToConfigurationList={toggleResponseModal}
					toggleResponseModal={toggleResponseModal}
					isModalOpen={responseModal.isResponseModalOpened}
					idConfiguration={currentConfiguration.savedConfigurationId}
					activityName={currentConfiguration.newInsertedConfiguration.activity_name}
					businessName={currentConfiguration.newInsertedConfiguration.business_name}
					vatCode={currentConfiguration.newInsertedConfiguration.vat_code}
					fiscalCode={currentConfiguration.newInsertedConfiguration.fiscal_code}
				/>
			) : (
				""
			)}
		</React.Fragment>
	);
    
}

export default List;